import React from "react";
import FilterDropDownSelectInputController from "./FilterDropDownSelectInput/FilterDropDownSelectInput.controller";
import { ClearButton, FiltersDiv } from "./JobFilters.styles";
import { isiPhone } from "@/utils/commonUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import ToggleSwitch from "@/outscal-commons-frontend/Styled/ToggleSwitch";

const JobFiltersView = ({
  filterUIObj,
  filters,
  clearFilters,
  disableFiltersClear = false,
  setFilters,
  setFiltersInQuery,
  hasjobCategoryTypeFilter,
  onToggleClick,
  enableGamingModeFilter = false,
}) => {
  return (
    <>
      <FiltersDiv isIphone={isiPhone()}>
        {enableGamingModeFilter && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ToggleSwitch
              analyticsText={`gaming_job_${hasjobCategoryTypeFilter}`}
              checked={hasjobCategoryTypeFilter}
              onChange={onToggleClick}
              checkedText="GAME"
              uncheckedText="ALL"
              borderRadius="6px"
            />
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              J&nbsp;O&nbsp;B&nbsp;S
            </p>
          </div>
        )}
        {filterUIObj.map((item) => {
          return (
            <FilterDropDownSelectInputController
              setFilters={setFilters}
              setFiltersInQuery={setFiltersInQuery}
              filters={filters[item.name] || []}
              key={item.name}
              name={item.name}
              label={item.label}
              optionsList={item.optionsList}
              defaultList={item.defaultList}
              customData={item.customData}
              filterAppliedLabel={item.filterAppliedLabel}
              width="300px"
              clearFilters={clearFilters}
            />
          );
        })}

        <ClearButton
          disableFiltersClear={disableFiltersClear}
          onClick={() => {
            clearFilters();
          }}
          data-analytics={[UIElements.BUTTON, "clear_job_filters"]}
        >
          Clear
        </ClearButton>
      </FiltersDiv>
    </>
  );
};

export default JobFiltersView;
