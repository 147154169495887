import React from "react";
import styled from "styled-components";
import { UIElements } from "../Hooks/useAnalyticsClickEvent";

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: ${(p) => (p.hasText ? "60px" : "auto")};
  min-width: 40px;
  height: 24px;
  padding: ${(p) => (p.hasText ? "0 18px" : "0")};
  @media screen and (max-width: 767px) {
    height: 20px;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: ${(p) => p.borderRadius || "24px"};

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: ${(p) => p.theme.colors.white};
    transition: 0.4s;
    border-radius: ${(p) => p.borderRadius || "50%"};
    @media screen and (max-width: 767px) {
      bottom: 0px;
      left: 0px;
    }
  }
`;

const CheckboxInput = styled.input`
  display: none;

  &:checked + ${Slider} {
    background-color: ${(p) => p.theme.colors.green};
  }

  &:checked + ${Slider}:before {
    transform: translateX(${(p) => (p.hasText ? "36px" : "16px")}); 
    @media screen and (max-width: 767px) {
      transform: translateX(40px);
    }
  }
`;

const TextLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  padding-right: ${(p) => (p.checked ? "20px" : "0")};
  padding-left: ${(p) => (p.checked ? "0" : "18px")};
  color: ${(p) => (p.checked ? "white" : "black")};
`;

const ToggleSwitch = ({ checked, onChange, analyticsText, checkedText = "", uncheckedText = "", borderRadius }) => {
  const hasText = !!(checkedText || uncheckedText);

  return (
    <SwitchContainer hasText={hasText}>
      <CheckboxInput
        hasText={hasText}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        data-analytics={[
          UIElements.BUTTON,
          `toggle${analyticsText ? "_" + analyticsText : ""}_${!checked}`,
        ]}
      />
      <Slider borderRadius={borderRadius}>
        {hasText && (
          <TextLabel checked={checked}>
            {checked ? checkedText : uncheckedText}
          </TextLabel>
        )}
      </Slider>
    </SwitchContainer>
  );
};

export default ToggleSwitch;
