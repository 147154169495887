import styled from "styled-components";
import { Button } from "@/outscal-commons-frontend/Styled/Buttons";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

export const DropdownBlur = styled.div`
  display: none;
  height: 0px;
  width: 0px;
  @media (max-width: 800px) {
    position: fixed;
    display: flex;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
  }
`;

export const DropdownSelectContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Chip = styled(Button)`
  background-color: ${({ isSelected, error, primaryColor }) =>
    error ? "#cc0621" : isSelected ? primaryColor : "#f5f5f5"};
  width: max-content;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};
  padding: 8px 11px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: ${({ isSelected, primaryColor }) =>
      isSelected ? "#fff" : primaryColor};
  }
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const RotatableArrow = styled(IoIosArrowDown)`
  font-size: 16px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const StyledCheckIcon = styled(FaCheckCircle)`
  font-size: 14px;
`;
